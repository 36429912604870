// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-tab-bar-background: var(--ion-color-secondary);
  --ion-font-family: 'montserrat';

  --ion-color-primary: #01E3DB;
  --ion-color-primary-rgb: 9,31,54;
  --ion-color-primary-contrast: #006583;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #01c8c1;
  --ion-color-primary-tint: #1ae6df;


  --swiper-theme-color: #049E99;
  --swiper-pagination-color: #049E99;


  --ion-color-secondary: #101827;
  --ion-color-secondary-rgb: 16,24,39;
  --ion-color-secondary-contrast: #01E3DB;
  --ion-color-secondary-contrast-rgb: 1,227,219;
  --ion-color-secondary-shade: #0e1522;
  --ion-color-secondary-tint: #282f3d;

  --ion-color-tertiary: #006583;
  --ion-color-tertiary-rgb: 0,101,131;
  --ion-color-tertiary-contrast: #01E3DB;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #005973;
  --ion-color-tertiary-tint: #1a748f;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45,211,111;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0,0,0;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255,196,9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235,68,90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146,148,156;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244,245,248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-toolbar-background: #fff;

  ion-content {
    --ion-background-color: transpatent;
  }
  body {
    background: #fff !important;
  }
  ion-card {
    --ion-background-color: #fff;
  }
  ion-item {
    --ion-background-color: #fff;
  }
  ion-card {
    border-radius: 18px;
    box-shadow: none;
  }


}
