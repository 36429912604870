/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

@import "fonts";


@import "swiper/css";
@import "swiper/css/navigation";
@import "swiper/css/pagination";

@tailwind base;
@tailwind components;
@tailwind utilities;


ion-loading.custom-loading {
  --background: var(--ion-color-secondary);
  --spinner-color: var(--ion-color-primary);

  color: var(--ion-color-primary);
}

input[type='radio'] {
  accent-color: #232323;
}

.action-sheet-button.sc-ion-action-sheet-ios {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: var(--ion-color-secondary) !important;
}

.header-ios ion-toolbar:last-of-type {
  border: none !important;
}

::ng-deep .header-ios ion-toolbar:last-of-type {
  border-width: 0 !important;
}

.ios {
  ion-list-header {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  ion-list-header {
    color: var(--ion-color-primary)
  }
  ion-item {
    font-size: 14px;
  }
  ion-datetime-button {
    font-size: 14px;
  }



}

.md {
  ion-list-header {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  ion-list-header {
    color: var(--ion-color-primary)
  }
  ion-item {
    font-size: 14px;
  }
  ion-datetime-button {
    font-size: 14px;
  }

  .sc-ion-searchbar-md-h {
    --background: var(--ion-color-primary-light);
  }

}


.iyb-toast {
  font-weight: 700 !important;
  text-transform: uppercase !important;
  font-size: 12px;
}

.mbsc-font {
  font-family: 'Prompt', serif !important;
}

.mbsc-ios.mbsc-selected .mbsc-calflex-endar-cell-text {
  box-shadow: rgba(0, 0, 0, 0.2) 0 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
}

.mbsc-ios.mbsc-selected .mbsc-calflex-endar-cell-text {
  border-color: #222428;
  background: #222428;
  color: #fff;
  border-radius: 8px;
}

ion-title {
  color: var(--ion-color-primary);
  font-size: 16px;
  font-weight: 700;
  font-style: italic;
}

.header-ios ion-toolbar:last-of-type {
  --border-width: 0 !important;
}

ion-button {
  font-weight: 600 !important;
}

.flux {
  .flux-header {
    display: flex;
    align-items: center;
    font-size: 16px;
    .athlete {
      .date {
        font-size: 10px;
        font-weight: 500;
        line-height: 18px;
        color: #666666;
      }
    }
    ion-avatar {
      margin-right: 18px
    }
  }
  ion-icon {
    font-size: 22px;
  }
}
.flux-image {
  max-width: 100%;
  border: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  max-height: 512px;
}
.flux-actions {
  width: 100%; display: flex; justify-content: flex-end; align-items: center
}
.athlete-infos {
  font-size: 12px
}


.flux-likes-comments {
  display: flex; align-items: center; margin-right: 12px; font-size: 11px; color: #666666;
  .likes {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    ion-avatar {
      width: 24px !important;
      height: 24px !important;
      line-height: 40px !important;
      img {
        width: 24px !important; height: 24px !important;
      }
    }
  }
  .likes-number {
    width: 26px;
    height: 26px;
    position: absolute;
    border-radius: 50%;
    background: #f5f5f5;
    top: 0;
    right: -3px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .like-button {
    ion-icon {
      font-size: 28px;
    }
  }
  .comment-button {
    ion-icon {
      font-size: 28px;
    }
  }
  .comments {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.likes-comments-infos {
  font-size: 12px;
  font-weight: 700;
  padding-left: 18px;
  margin-bottom: 8px;
  position: relative;
  z-index: 999;
}

.comments-container {
  margin-bottom: 18px;
  .comments {
    display: flex;
    padding-top: 18px;
    justify-content: center;
    .athlete {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
      ion-avatar {
        box-shadow: rgba(0, 0, 0, 0.12) 1px 1px;
      }
    }
    .comment-athlete-infos {
      font-weight: 600;
      font-size: 12px !important;
    }
    .comment-text {
      line-height: 24px;
      font-size: 12px;
      font-weight: 500;
    }
    .date {
      font-size: 10px;
      font-weight: 500;
      line-height: 18px;
      color: #666666;
      margin-left: 12px;
    }

    .comment-content {
      display: flex;
      flex-direction: column;
      background: #fff;
      padding-left: 12px;
      width: 80vw;
      justify-content: center;
      padding-top: 8px;
      padding-bottom: 8px;
      border-radius: 24px;
      font-size: 14px;
    }

  }
}
.input-label-placement-stacked.sc-ion-input-ios-h .input-wrapper.sc-ion-input-ios, .input-label-placement-floating.sc-ion-input-ios-h .input-wrapper.sc-ion-input-ios {
  color: var(--ion-color-tertiary);
  font-weight: 700;
}

.textarea-label-placement-stacked.sc-ion-textarea-ios-h .textarea-wrapper.sc-ion-textarea-ios, .textarea-label-placement-floating.sc-ion-textarea-ios-h .textarea-wrapper.sc-ion-textarea-ios {
  color: var(--ion-color-tertiary);
  font-weight: 700;
}

.rounded-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--ion-color-primary);
  padding: 4px;
  margin-right: 4px;
  border-radius: 50%;
  ion-icon {
    font-size: 18px; color: #fff;
  }
}


.rounded-icon-danger {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--ion-color-danger);
  padding: 4px;
  box-shadow: rgba(0, 0, 0, 0.12) 1px 1px;
  margin-right: 4px;
  border-radius: 50%;
  ion-icon {
    font-size: 18px; color: #fff;
  }
}

.rounded-icon-warning {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--ion-color-warning);
  padding: 4px;
  box-shadow: rgba(0, 0, 0, 0.12) 1px 1px;
  margin-right: 4px;
  border-radius: 50%;
  ion-icon {
    font-size: 18px; color: #fff;
  }
}


.mbsc-ios.mbsc-datepicker .mbsc-selected .mbsc-calendar-day-text, app-plan-daily .mbsc-ios.mbsc-datepicker .mbsc-highlighted .mbsc-calendar-day-text, app-plan-daily .mbsc-ios.mbsc-datepicker .mbsc-range-hover-start .mbsc-calendar-day-text, app-plan-daily .mbsc-ios.mbsc-datepicker .mbsc-range-hover-end .mbsc-calendar-day-text, app-plan-daily .mbsc-ios.mbsc-datepicker .mbsc-hover .mbsc-calendar-day-text {
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.12) 0 4px 16px
}

ion-refresher ion-refresher-content {
  --color: var(--ion-color-primary) !important;
}

ion-content {
  background: #fff;
}

ion-header {
  position: relative !important;
  background-color: var(--ion-color-secondary) !important;
  overflow: hidden;
}

ion-toolbar {
  --background: transparent !important;
}
.header-ios ion-toolbar:last-of-type {
  //border-bottom-left-radius: 42px 42px !important;
}

.number {
  font-family: "Martian Mono", monospace !important;
}
.alert-message {
  color: #fff !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 22px !important;
  text-align: left !important;
}
.geo-1-s {
  position: absolute;
  top: -4px;
  right: -5px;
  z-index: 99;
  opacity: 0.2;
  pointer-events: none;
  img {
    width: 116px !important;
  }
}

.toast-wrapper {
  font-weight: 600 !important;
}

.geo-2-s {
  position: absolute;
  top: -13px;
  left: 5px;
  z-index: 99;
  opacity: 0.2;
  pointer-events: none;
  img {
    width: 116px !important;
  }
}


ion-button {
  font-size: 11px;
}
ion-tab-bar {
  border-radius: 12px;
  padding-bottom: 0!important;
  margin-right: 4px;
  margin-left: 4px;
}
ion-tabs {
  height: 98% !important;
  ion-icon {
    font-size: 28px !important;
  }
}
.tab-selected {
  ion-icon {
    color: var(--ion-color-primary) !important;
  }
}
ion-toolbar {
  ion-button {
    ion-icon {
      font-size: 26px !important;
    }
  }
}

.confirm-scheduler {
  --background: red;
}

.alert-title.sc-ion-alert-ios {
  margin-top: 8px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 26px;
}

ion-alert.custom-alert {
  --backdrop-opacity: 0.3;
}

.custom-alert .alert-button-group {
  padding: 8px;
}

.alert-button-role-confirm {
  //background-color: var(--ion-color-success) !important;
  border: 0.55px solid rgba(var(--ion-color-danger, 0, 0, 0), 0.2) !important;
  border-radius: 24px !important;
  color:var(--ion-color-tertiary) !important;
  font-size: 12px !important;
  height: 34px !important;
  font-weight: 600;
}
.alert-wrapper.sc-ion-alert-ios {
  background: var(--ion-color-secondary);
}
.alert-button-role-cancel {
  //background-color: var(--ion-color-danger) !important;
  border: 0.55px solid rgba(var(--ion-color-danger, 0, 0, 0), 0.2) !important;
  border-radius: 24px !important;
  color: var(--ion-color-danger) !important;
  font-size: 12px !important;
  height: 34px !important;
}




.ios .custom-alert button.alert-button {
}

.ios button.alert-button.alert-button-cancel {
  border-right: 0;
  border-bottom-left-radius: 13px;
  border-top-left-radius: 13px;
}

.ios button.alert-button.alert-button-confirm {
  border-bottom-right-radius: 13px;
  border-top-right-radius: 13px;
}


