@font-face {
  font-family: 'Prompt';
  src: url('assets/fonts/Prompt-Bold.woff2') format('woff2'),
  url('assets/fonts/Prompt-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Prompt';
  src: url('assets/fonts/Prompt-BoldItalic.woff2') format('woff2'),
  url('assets/fonts/Prompt-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Prompt';
  src: url('assets/fonts/Prompt-ExtraBold.woff2') format('woff2'),
  url('assets/fonts/Prompt-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Prompt';
  src: url('assets/fonts/Prompt-Black.woff2') format('woff2'),
  url('assets/fonts/Prompt-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Prompt';
  src: url('assets/fonts/Prompt-ExtraBoldItalic.woff2') format('woff2'),
  url('assets/fonts/Prompt-ExtraBoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Prompt';
  src: url('assets/fonts/Prompt-BlackItalic.woff2') format('woff2'),
  url('assets/fonts/Prompt-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Prompt';
  src: url('assets/fonts/Prompt-Regular.woff2') format('woff2'),
  url('assets/fonts/Prompt-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Prompt';
  src: url('assets/fonts/Prompt-ExtraLight.woff2') format('woff2'),
  url('assets/fonts/Prompt-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Prompt';
  src: url('assets/fonts/Prompt-MediumItalic.woff2') format('woff2'),
  url('assets/fonts/Prompt-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Prompt';
  src: url('assets/fonts/Prompt-Medium.woff2') format('woff2'),
  url('assets/fonts/Prompt-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Prompt';
  src: url('assets/fonts/Prompt-Light.woff2') format('woff2'),
  url('assets/fonts/Prompt-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Prompt';
  src: url('assets/fonts/Prompt-SemiBold.woff2') format('woff2'),
  url('assets/fonts/Prompt-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Prompt';
  src: url('assets/fonts/Prompt-LightItalic.woff2') format('woff2'),
  url('assets/fonts/Prompt-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Prompt';
  src: url('assets/fonts/Prompt-ExtraLightItalic.woff2') format('woff2'),
  url('assets/fonts/Prompt-ExtraLightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Prompt';
  src: url('assets/fonts/Prompt-Italic.woff2') format('woff2'),
  url('assets/fonts/Prompt-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Prompt';
  src: url('assets/fonts/Prompt-Thin.woff2') format('woff2'),
  url('assets/fonts/Prompt-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Prompt';
  src: url('assets/fonts/Prompt-ThinItalic.woff2') format('woff2'),
  url('assets/fonts/Prompt-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Prompt';
  src: url('assets/fonts/Prompt-SemiBoldItalic.woff2') format('woff2'),
  url('assets/fonts/Prompt-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Digital Numbers Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Digital Numbers Regular'), url('assets/fonts/DigitalNumbers-Regular.woff') format('woff');
}

